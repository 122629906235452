// Sidebar
$sidebar-width-lg: 240px;
$sidebar-folded-width: 70px;

$sidebar-light-bg: $white;
$sidebar-light-border-color: #f2f4f9;

$sidebar-dark-bg: #0c1427;
$sidebar-dark-border-color: #172340;
// Sidebar

// Navbar
$navbar-height: 60px;
// Navbar